import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse, HttpHeaders,} from "@angular/common/http";
import {Observable} from "rxjs";
import {EnvironmentService} from "../environment/environment.service";
import {CurrentUserService} from "../currentUser/current-user.service";

export interface ICreateConsentInfo {
  identifiers: IIdentifier[];
}

export interface IConsentInfo {
  consentTypes: IConsentType[];
}

export interface IConsentType {
  identifier: IIdentifier;
  label: ILabel;
  shortDescription: ILabel;
  longDescription: ILabel;
}

export interface IIdentifier {
  code: string;
  version: string;
}

export interface ILabel {
  fr: string;
  nl: string;
  de: string;
  en: string;
}

export interface IUser {
  name: string;
  nationalNumber: string;
  companyName: ILabel;
  companyNumber: string;
}

@Injectable({
  providedIn: "root",
})

export class ConsentService {
  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private currentUserService: CurrentUserService
  ) {}

  /**
   * Send a message to the backend
   *
   * @returns Observable<IConsentType>
   */
  getConsentInfos(): Observable<IConsentInfo> {
    const url = this.getConsentServiceUrl();

    return this.genericHandlingError(
      this.http.get<IConsentInfo>(
        url + "/public/consentRequests",
        this.getHttpOptions()
      )
    );
  }

  /**
   * Send a message to the backend
   *
   * @returns Observable<never>
   */
  sendConsentResponse(confirmationConsentInfo: ICreateConsentInfo): Observable<any> {
    const url = this.getConsentServiceUrl();

    // Note: the authorization header is not added here, it will be added by the OidcHttpInterceptor
    return this.genericHandlingError(
      this.http.post(url + "/public/consentResponses", confirmationConsentInfo, this.getHttpOptions())
    );
  }

  getUserInfos(): Observable<IUser> {
    const url = this.getConsentServiceUrl();

    return this.genericHandlingError(
      this.http.get<IUser>(url + "/public/users", this.getHttpOptions())
    );
  }

  /**
   * Return Http options
   */
  getHttpOptions(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Cache-Control": "no-cache",
        "consent-request-header": this.currentUserService.consentRequest
      }),
    };
  }

  /**
   * Get the url from server environment
   *
   * @returns string is the url for the service
   */
  getConsentServiceUrl(): string {
    return this.environmentService
      .getEnvironment()
      .servers.find((server) => server.serverId === "consent").serverUrl;
  }

  /**
   * Handle generically every error
   *
   * @param request any
   * @private
   */
  private genericHandlingError<t>(
    request: Observable<t>
  ): Observable<t> {
    return new Observable((subscriber) => {
      request.subscribe(
        (result) => {
          subscriber.next(result);
        },
        (error: HttpErrorResponse) => {
          subscriber.error(error);
        }
      );
    });
  }
}
