import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {GenericErrorComponent} from "./shared/components/generic-error/generic-error.component";
import {GenericErrorResolver} from "./shared/components/generic-error/generic-error.resolver";
import {ConsentComponent} from "./shared/components/consent/consent.component";


export const appRouteList: Routes = [
  { path: '', component: ConsentComponent },
  {
    path: 'error',
    component: GenericErrorComponent,
    resolve: {
      errorData: GenericErrorResolver,
    },
  },
  { path: '**', redirectTo: 'error' }
];

@NgModule({
  exports: [
    RouterModule
  ],
  imports: [
    RouterModule.forRoot(appRouteList)
  ],
})
export class AppRoutingModule {
}
