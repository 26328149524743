import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ConsentService } from "./shared/services/consent/consent.service";
import { Environment, Version } from "../environments/types/environment";
import { EnvironmentLoaderService } from "../environments/environment-loader.service";
import {
  CurrentUserService,
  UserPermission,
} from "./shared/services/currentUser/current-user.service";

export interface Server {
  serverId: string;
  serverUrl: string;
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "cicdtpl-angular";
  currentUserID: string;
  version: string;
  appId: string;
  servers: Server[];
  permissions: UserPermission[];
  sendMessageFormGroup: FormGroup = new FormGroup({
    msgControl: new FormControl("hello", Validators.required),
  });
  responseMessages: Array<string> = [];

  constructor(
    private consentService: ConsentService,
    private environmentLoaderService: EnvironmentLoaderService,
    private currentUserService: CurrentUserService
  ) {}

  ngOnInit(): void {
    void this.environmentLoaderService.loadVersion().then((versionResult) => {
      this.version = (versionResult.value as Version).version;
    });
    void this.environmentLoaderService.loadEnvironment().then((environment) => {
      this.servers = (environment.value as Environment).servers;
    });
    this.currentUserID = this.currentUserService.userId;
    this.appId = this.currentUserService.appId;
    this.permissions = this.currentUserService.userPermissions;
  }
}
