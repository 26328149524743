import {APP_INITIALIZER, NgModule} from "@angular/core";
import {AppComponent} from "./app.component";
import {RouterModule} from "@angular/router";
import {SharedModule} from "./shared/shared.module";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {MultiTranslateHttpLoader} from "ngx-translate-multi-http-loader";
import {TranslateLoader, TranslateModule, TranslateModuleConfig, TranslateService,} from "@ngx-translate/core";
import {CurrentUserService} from "./shared/services/currentUser/current-user.service";
import {EnvironmentLoaderService} from "../environments/environment-loader.service";
import {IAppConfig} from "../environments/types/request.result";
import {CommonModule} from "@angular/common";
import {ReactiveFormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {AppRoutingModule} from "./app.routing.module";
import {RequestInterceptor} from "./shared/services/error/interceptor.service";
import packageInfo from 'package.json';
import {FinanceComponentsModule} from '@be-fgov-minfin/designcom-components';

// Config to load translation files from assets/i18n/{lang}.json
/**
 * Return a clientLoaderFactory
 *
 * @param http client to perform HTTP Request
 * @returns MultiTranslateHttpLoader
 */
export function httpLoaderFactory(http: HttpClient): MultiTranslateHttpLoader {
  return new MultiTranslateHttpLoader(http, [
    { prefix: "./assets/i18n/", suffix: ".json" },
  ]);
}

/**
 * Return the translateConfiguration of the application
 *
 * @returns TranslateModuleConfig
 */
export function translateConfig(): TranslateModuleConfig {
  return {
    loader: {
      provide: TranslateLoader,
      useFactory: httpLoaderFactory,
      deps: [HttpClient],
    },
  };
}

/**
 * Init the application translation and environment variable
 *
 * @param userService which will return information of active user
 * @param translate service to provide translation
 * @param environmentLoader service to provide environment args and version
 * @returns Promise<Void>
 */
export function initApp(
  userService: CurrentUserService,
  translate: TranslateService,
  environmentLoader: EnvironmentLoaderService
): () => Promise<void> {
  return () =>
    environmentLoader.loadEnvironment().then((queryResult: IAppConfig) => {
      userService.init(queryResult.request);
      translate.setDefaultLang("en");
      translate.use(userService.appLanguage);
    });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    RouterModule,
    CommonModule,
    TranslateModule,
    SharedModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot(translateConfig()),
    AppRoutingModule,
    FinanceComponentsModule.forRoot(packageInfo.version),
  ],
  providers: [
    EnvironmentLoaderService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [CurrentUserService, TranslateService, EnvironmentLoaderService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
