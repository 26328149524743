import { Injectable } from "@angular/core";
import { EnvironmentService } from "../environment/environment.service";

export type UserPermission = {
  [key: string]: { [key: string]: boolean | string };
};

@Injectable({
  providedIn: "root",
})
/**
 * Service to store and manage the current application user with the associated session tokens.
 */
export class CurrentUserService {
  public userPermissions: UserPermission[];
  public appId: string;
  private userIdValue: string;
  private userNameValue: string;
  private preferredLanguageValue: string;
  private authorizationTokenValue: string;
  private iamUserTokenValue: string;
  private applicationLanguage: string;
  private consentRequestString: string;

  constructor(private environmentService: EnvironmentService) {}

  /**
   * Get the user Id
   */
  get userId(): string {
    return this.userIdValue;
  }

  /**
   * Get the user fullName
   */
  get userFullName(): string {
    return this.userNameValue;
  }

  /**
   * Get the preferred language
   */
  get preferredLanguage(): string {
    return this.preferredLanguageValue;
  }

  /**
   * Get the authorization Bearer Token
   */
  get authorizationToken(): string {
    return this.authorizationTokenValue;
  }

  /**
   * Get Iam User Token
   */
  get iamUserToken(): string {
    return this.iamUserTokenValue;
  }

  /**
   * Get the application language
   */
  get appLanguage(): string {
    return this.applicationLanguage;
  }

  /**
   * Set the application language
   *
   * @param newLanguage is a string
   */
  set appLanguage(newLanguage: string) {
    this.applicationLanguage = newLanguage;
  }


  /**
   * Get the consentRequest
   */
  get consentRequest(): string {
    return this.consentRequestString;
  }

  /**
   * Set the consentRequest
   *
   * @param newConsentRequest is a string
   */
  set consentRequest(newConsentRequest: string) {
    this.consentRequestString = newConsentRequest;
  }
  /**
   * Init the request to have the all information from a user
   *
   * @param request use to get the information
   */
  init(request: XMLHttpRequest): void {
    const authorizationToken = request.getResponseHeader("authorization");
    const userToken = request.getResponseHeader("minfin-iam-usertoken");
    if (authorizationToken && userToken) {
      // parse the minfin-iam-usertoken to get the user info we need
      const startIndex = userToken.indexOf(".") + 1;
      const userBase64 = userToken.slice(
        startIndex,
        userToken.indexOf(".", startIndex)
      );
      const userData = JSON.parse(atob(userBase64)) as Record<string, string>;
      this.userIdValue = userData["sub"];
      this.userNameValue = `${userData["givenName"]} ${userData["sn"]}`;
      this.preferredLanguageValue = userData["preferredLanguage"];
      this.authorizationTokenValue = authorizationToken;
      this.iamUserTokenValue = userToken;
      this.appId = userData["client_id"];
      this.userPermissions = userData["permissions"][
        this.appId
      ] as UserPermission[];
    } else {
      if (this.environmentService.getProduction()) {
        throw new Error(
          "User not authenticated (No authorizationToken and/or minfin-iam-usertoken)"
        );
      }
    }
    this.applicationLanguage =
      localStorage.getItem("language") === null
        ? this.preferredLanguageValue
        : localStorage.getItem("language");
  }
}
