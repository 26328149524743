import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { default as environment } from "./assets/environments/environment.json";
import { EnvironmentLoaderService } from "./environments/environment-loader.service";
import { Version } from "./environments/types/environment";

if (environment.production) {
  enableProdMode();
}

async function main(): Promise<void> {
  await platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((ngModuleReference) => {
      const environmentLoaderService = ngModuleReference.injector.get(
        EnvironmentLoaderService
      );
      environmentLoaderService.loadVersion().then((versionResult) => {
        const version = (versionResult.value as Version).version;
        const oldVersion = localStorage.getItem("version");
        const oldTheme = localStorage.getItem("theme");
        if (oldVersion !== version) {
          console.log(`Version changed from ${oldVersion} to ${version}`);
          localStorage.clear();
          localStorage.setItem("version", version);
          localStorage.setItem("theme", oldTheme);
        }
      });
    });
}

main().catch((error) => console.error(error));
