import { default as defaultEnvironment } from "../assets/environments/environment.json";
import { default as defaultVersion } from "../assets/environments/version.json";
import { Environment, Version } from "./types/environment";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { IAppConfig } from "./types/request.result";

@Injectable({
  providedIn: "root",
})
export class EnvironmentLoaderService {
  private BASE_URL = "./assets/environments/";
  private environmentFile = "environment.json";
  private versionFile = "version.json";
  private environment: IAppConfig;
  private version: BehaviorSubject<IAppConfig> =
    new BehaviorSubject<IAppConfig>(undefined);

  constructor() {}

  loadEnvironment(): Promise<IAppConfig> {
    return new Promise<IAppConfig>((resolve) => {
      const xmlHttp = new XMLHttpRequest();
      const method = "GET";
      xmlHttp.open(method, `${this.BASE_URL}${this.environmentFile}`, true);
      xmlHttp.addEventListener("load", () => {
        this.environment = {
          request: xmlHttp,
          value:
            xmlHttp.status === 200
              ? JSON.parse(xmlHttp.responseText)
              : (defaultEnvironment as Environment),
        };
        resolve(this.environment);
      });
      xmlHttp.send();
    });
  }

  getEnvironment(): IAppConfig {
    return this.environment;
  }

  loadVersion(): Promise<IAppConfig> {
    return new Promise<IAppConfig>((resolve) => {
      const xmlHttp = new XMLHttpRequest();
      const method = "GET";
      xmlHttp.open(method, `${this.BASE_URL}${this.versionFile}`, true);
      xmlHttp.addEventListener("load", () => {
        this.version.next({
          request: xmlHttp,
          value:
            xmlHttp.status === 200
              ? (JSON.parse(xmlHttp.responseText) as Version)
              : (defaultVersion as Version),
        });
        resolve(this.version.value);
      });
      xmlHttp.addEventListener("error", () => {
        this.version.next({
          request: xmlHttp,
          value: defaultVersion as Version,
        });
        resolve(this.version.value);
      });
      xmlHttp.send();
    });
  }

  getVersion(): BehaviorSubject<IAppConfig> {
    return this.version;
  }
}
