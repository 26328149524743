import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {ConsentTableComponent} from "./components/consent-table/consent-table.component";
import {GenericErrorComponent} from "./components/generic-error/generic-error.component";
import {ConsentComponent} from "./components/consent/consent.component";
import {FooterModule, HeaderModule} from "@be-fgov-minfin/designcom-components";
import {MatButton, MatButtonModule} from "@angular/material/button";
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatError} from "@angular/material/form-field";
import {MatMenu, MatMenuTrigger} from "@angular/material/menu";
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCardModule} from "@angular/material/card";

@NgModule({
    declarations: [ConsentComponent, ConsentTableComponent, GenericErrorComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        FormsModule,
        HeaderModule,
        FooterModule,
        MatButton,
        MatIconModule,
        MatButtonModule,
        BrowserAnimationsModule,
        MatError,
        MatCardModule,
        MatMenuTrigger,
        MatMenu,
        MatExpansionModule,
        MatCheckboxModule
    ],
  exports: [ConsentTableComponent],
})
export class SharedModule {}
