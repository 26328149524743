import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {EMPTY, Observable} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.scss']
})
export class GenericErrorComponent implements OnInit {
  constructor(private readonly route: ActivatedRoute) {}

  data: Observable<{ code: number; message: string } | undefined> = EMPTY;

  ngOnInit(): void {
    this.data = this.route.data.pipe(
        map(
            (data) =>
                (data.errorData as { code: number; message: string }) || undefined
        )
    );
  }

}


