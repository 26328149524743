import {Component, OnInit} from '@angular/core';
import {
  ConsentService,
  IConsentInfo,
  IConsentType,
  IIdentifier,
  ILabel,
  IUser
} from "../../services/consent/consent.service";
import {HttpErrorResponse} from "@angular/common/http";
import {AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn} from "@angular/forms";
import {CurrentUserService} from "../../services/currentUser/current-user.service";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Component({
  selector: 'app-consent-table',
  templateUrl: './consent-table.component.html',
  styleUrls: ['./consent-table.component.scss']
})
export class ConsentTableComponent implements OnInit {
  userInfo$: Observable<IUser>;
  consentTypes$: Observable<IConsentType[]>;
  consentTypesValues: IConsentType[] = []
  form: FormGroup;
  companyName: string;

  constructor(
      private consentService: ConsentService,
      private currentUserService: CurrentUserService,
      private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.loadData();
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      checkboxes: this.formBuilder.array([], this.minSelectedCheckboxes())
    });
  }

  private loadData(): void {
    this.userInfo$ = this.consentService.getUserInfos().pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Error fetching user info: ${error.message}`);
          this.sendConsentResponse(false);
          return new Observable<IUser>();
        })
    );

    this.consentTypes$ = this.consentService.getConsentInfos().pipe(
        map((response: IConsentInfo) => {
          this.populateCheckboxes(response.consentTypes);
          return response.consentTypes;
        }),
        catchError((error: HttpErrorResponse) => {
          console.error(`Error fetching consent types: ${error.message}`);
          this.sendConsentResponse(false);
          return new Observable<IConsentType[]>();
        })
    );
    this.consentTypes$.subscribe(consentTypes => {
      this.consentTypesValues = consentTypes;
    });
  }

  private populateCheckboxes(consentTypes: IConsentType[]): void {
    const checkboxes = this.form.get('checkboxes') as FormArray;
    consentTypes.forEach(() => checkboxes.push(this.formBuilder.control(false)));
  }

  minSelectedCheckboxes(): ValidatorFn {
    return (formArray: AbstractControl): { [key: string]: boolean } | null => {
      const totalSelected = (formArray as FormArray).controls
          .map(control => control.value)
          .reduce((prev, next) => next ? prev + next : prev, 0);
      return totalSelected >= 1 ? null : { required: true };
    };
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.sendConsentResponse(true);
    }
  }

  private sendConsentResponse(isConsentAccepted: boolean): void {
    if (this.form.valid || !isConsentAccepted) {
      const confirmationConsentInfo = {
        identifiers: this.getIdentifiers(),
        consentAccepted: isConsentAccepted
      };
      this.consentService.sendConsentResponse(confirmationConsentInfo).subscribe(
          res => this.handleConsentResponse(res),
          (error: HttpErrorResponse) => {
            console.error(`Error sending consent response: ${error.message}`);
          }
      );
    }
  }

  private handleConsentResponse(res): void {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = res.responseUrl;
    form.enctype = "application/x-www-form-urlencoded";
    form.style.display = 'none';

    const hiddenField = document.createElement('input');
    hiddenField.type = "hidden";
    hiddenField.name = "consent_response";
    hiddenField.value = res.jwtResponse;
    form.appendChild(hiddenField);

    document.body.appendChild(form);
    form.submit();
  }

  getTranslation(label: ILabel): string {
    return label[this.currentUserService.appLanguage];
  }

  onRefused(): void {
    this.sendConsentResponse(false);
  }

  private getIdentifiers(): IIdentifier[] {
    const identifiers: IIdentifier[] = [];
    const checkboxes = this.form.get('checkboxes') as FormArray;

    checkboxes.value.forEach((value, i) => {
      if (value && this.consentTypesValues[i]) {
        identifiers.push(this.consentTypesValues[i].identifier);
      }
    });

    return identifiers;
  }
}
