<form class="container" [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="consentTypes$ | async as consentTypesValues">
  <h2 class="subtitle" *ngIf="userInfo$ | async as userInfoValues">
    {{ 'consent.subtitle-welcome' | translate }} {{ userInfoValues.name }}<b *ngIf="this.companyName">{{ 'consent.subtitle-rep' | translate }} {{ this.companyName }}</b>
  </h2>
  <div class="flex-container">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          {{ 'consent.consentTitleHeader' | translate }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content *ngIf="consentTypesValues && consentTypesValues.length > 0; else noConsentTypes">
        <mat-accordion>
          <mat-expansion-panel
                  *ngFor="let consentType of consentTypesValues; index as i"
                  [class.expansion-panel-checked]="form.get('checkboxes').at(i).value"
                  formArrayName="checkboxes">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ getTranslation(consentType.label) }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div [innerHTML]="getTranslation(consentType.longDescription)"></div>
            <mat-checkbox [formControlName]="i" id="{{ 'checkbox-' + i }}">
              {{ 'consent.consentCheckboxLabel' | translate }}
            </mat-checkbox>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-card-content>
      <ng-template #noConsentTypes>
        <div>No consent types available</div>
      </ng-template>
    </mat-card>

    <div *ngIf="form.get('checkboxes')?.errors?.required" class="alert-warning">
      {{ 'consent.scopeMinRequired' | translate }}
    </div>
  </div>

  <div class="button-group">
    <button id="refuse-button" mat-stroked-button color="secondary" (click)="onRefused()">
      <mat-icon svgIcon="cancel"></mat-icon>
      {{ 'consent.cancel' | translate }}
    </button>
    <button type="submit" id="accept-button" mat-flat-button color="tertiary" [disabled]="!form.valid">
      <mat-icon svgIcon="valid"></mat-icon>
      {{ 'consent.agree' | translate }}
    </button>
  </div>
</form>
