import {Component, Input, InputSignal, OnDestroy, OnInit, signal, WritableSignal} from '@angular/core';
import {CurrentUserService} from "../../services/currentUser/current-user.service";
import {ActivatedRoute} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {Title} from "@angular/platform-browser";
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent implements OnInit, OnDestroy {
  logoUrl: string = 'assets/images/Consent_logo.png';
  currentLanguage: string = 'en';
  supportedLanguages = [{ id: 'fr', label: 'FR' }, { id: 'nl', label: 'NL' }, { id: 'de', label: 'DE' }, { id: 'en', label: 'EN' }];
  translateSubscription: Subscription;

  constructor(
      private route: ActivatedRoute,
      private currentUserService: CurrentUserService,
      private translateService: TranslateService,
      private titleService: Title
  ) {}

  ngOnInit(): void {
    this.initializeConsentRequest();
    this.initializeLanguage();
  }

  ngOnDestroy(): void {
    if (this.translateSubscription) {
      this.translateSubscription.unsubscribe();
    }
  }

  private initializeConsentRequest(): void {
    this.currentUserService.consentRequest = this.route.snapshot.queryParamMap.get("consent_request");
  }

  private initializeLanguage(): void {
    const storedLanguage = this.currentUserService.appLanguage || localStorage.getItem('lang');
    this.currentLanguage = this.isValidLanguage(storedLanguage) ? storedLanguage.toLowerCase() : 'en';
    this.changeLanguage(this.currentLanguage);
  }

  changeLanguage(language: string): void {
    if (this.isValidLanguage(language)) {
      this.currentUserService.appLanguage = language;
      this.currentLanguage = language;
      localStorage.setItem('lang', this.currentLanguage);
      this.translateService.use(this.currentLanguage);
      this.translateSubscription = this.translateService.get('Consents').subscribe(
          (translatedTitle: string) => {
            this.titleService.setTitle(translatedTitle);
          },
          error => {
            console.error('Error translating title', error);
          }
      );
    } else {
      console.warn(`Unsupported language: ${language}`);
    }
  }

  private isValidLanguage(language: string | null): boolean {
    return this.supportedLanguages.map(lang => lang['id']).includes(language?.toLowerCase() || '');
  }

}
