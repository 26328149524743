import { Injectable } from "@angular/core";
import { Server } from "../../../../environments/types/server";
import { Environment } from "../../../../environments/types/environment";
import { EnvironmentLoaderService } from "../../../../environments/environment-loader.service";

@Injectable({
  providedIn: "root",
})
export class EnvironmentService {
  constructor(private environmentLoader: EnvironmentLoaderService) {}

  getEnvironment = (): Environment =>
    this.environmentLoader.getEnvironment().value as Environment;

  getServers = (): Array<Server> => this.getEnvironment().servers;

  getServerSitran = (): string => this.getEnvironment().serversitran;

  getIamLdap = (): string => this.getEnvironment().iamLdapUrl;

  getProduction = (): boolean => this.getEnvironment().production;

  getServerDocFactory = (): string => this.getEnvironment().serverdocFactory;

  getTaskRefreshPeriod = (): number => this.getEnvironment().taskRefreshPeriod;

  getServer = (solutionId: string): string =>
    `${
      this.getEnvironment().servers.find(
        (server) => server.serverId === solutionId
      ).serverUrl
    }/int/`;

  getServerImg = (solutionId: string): string =>
    this.getEnvironment().servers.find(
      (server) => server.serverId === solutionId
    ).serverUrl;

  getServerRefreshPeriod = (): number =>
    this.getEnvironment().serverRefreshPeriod;

  getWithCredentials = (): boolean => this.getEnvironment().withCredentials;
}
