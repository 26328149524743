<div>
  <fdc-header><div userInfo></div></fdc-header>
  <div class="container mt-3">
    <ng-container *ngIf="data | async as error">
      <mat-card>
        <mat-card-header>
          <mat-card-title>{{ 'consent.error.' + error.code + '.title' | translate}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>{{ 'consent.error.' + error.code + '.message' | translate}}</p>
        </mat-card-content>
      </mat-card>
    </ng-container>
  </div>

  <fdc-footer></fdc-footer>
</div>
